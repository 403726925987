<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card tag="article">
            <b-card-text>
              <b-form-group
                id="input-group-1"
                label="BUSCAR PACIENTE:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.dni"
                  type="search"
                  required
                  placeholder="Ingrese el DNI/Historia clínica"
                  v-on:keyup.enter="searchPerson"
                ></b-form-input>

                <b-table-simple hover small caption-top responsive>
                  <b-thead>
                    <b-tr>
                      <b-th>Tipo documento</b-th>
                      <b-th>Nº documento</b-th>
                      <b-th>Nombre y apellidos</b-th>
                      <b-th>Edad</b-th>
                      <b-th>Género</b-th>
                      <b-th>Acciones</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-td>{{ person.document_type }}</b-td>
                      <b-td>{{ person.dni }}</b-td>
                      <b-td
                        >{{ person.name }} {{ person.last_name }}
                        {{ person.last_name_2 }}
                      </b-td>
                      <b-td>{{ person.timesince }}</b-td>
                      <b-td>{{ person.gender }}</b-td>
                      <b-td>
                        <b-button
                          v-if="person.has_medical_record === false"
                          variant="success"
                          @click="editPerson"
                          >Crear historia
                        </b-button>
                        <b-button
                          v-if="person.has_medical_record === true"
                          variant="success"
                          @click="editPerson"
                          class="ml-1"
                          >Actualizar historia
                        </b-button>

                        <b-button
                          v-if="person.has_medical_record === true"
                          variant="primary"
                          @click="createAppointment"
                          class="ml-1"
                          >Crear cita
                        </b-button>
                        <!--
                        <b-button
                          id="laboratory_icon"
                          variant="primary"
                          v-if="person.has_medical_record === true"
                          @click="createLaboratoryAppointment"
                        >
                          <b-icon
                            icon="droplet-fill"
                            aria-label="Help"
                          ></b-icon>
                        </b-button>
                        -->
                        <b-button
                          variant="primary"
                          v-if="person.has_medical_record === true"
                          @click="createGlobalAppointment"
                          title="Crear nueva cita/examen/procedimiento"
                          class="ml-1"
                        >
                          <b-icon
                            icon="alarm-fill"
                            aria-label="Crear nueva cita/examen/procedimiento"
                          ></b-icon>
                        </b-button>
                        <b-button
                          variant="primary"
                          v-if="person.has_medical_record === true"
                          @click="personHistory"
                          title="Reporte de atenciones"
                          class="ml-1"
                        >
                          <b-icon
                            icon="clock-history"
                            aria-label="Reporte de atenciones"
                          ></b-icon>
                        </b-button>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-form-group>
            </b-card-text>
            <b-button @click="searchPerson" variant="primary">Buscar</b-button>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import XLSX from "xlsx";

export default {
  data() {
    return {
      form: {
        dni: ""
      },
      person: {},
      fields: [
        {
          key: "document_type",
          label: "Tipo documento"
        },
        {
          key: "dni",
          label: "Número documento"
        },
        {
          key: "full_name",
          label: "Nombres y apellidos"
        },
        {
          key: "action",
          label: "Acciones"
        }
      ]
    };
  },
  methods: {
    personHistory() {
      const person = this.person.id;
      ApiService.get(`/person/${person}/history`).then(res => {
        console.log(res);
        let buffer = [];
        const ordered_buffer = [
          ...res.data.citas,
          ...res.data.laboratorios
        ].sort((a, b) => {
          return a.created_at > b.created_at ? 1 : -1;
        });
        console.log(ordered_buffer);
        ordered_buffer.forEach(index => {
          if (index.hasOwnProperty("analysis_details")) {
            index.analysis_details.forEach(index2 => {
              console.log(index2);
              let obj = {
                FECHA: index.created_at,
                PACIENTE: index.person,
                ESPECIALIDAD: index.specialty,
                "PROFESIONAL DE LA SALUD": index.doctor,
                PRECIO: index2.price,
                EXAMEN: index2.name
              };
              buffer.push(obj);
            });
          } else {
            let obj = {
              FECHA: index.created_at,
              PACIENTE: index.person,
              ESPECIALIDAD: index.specialty,
              "PROFESIONAL DE LA SALUD": index.doctor,
              PRECIO: parseFloat(index.price)
            };
            buffer.push(obj);
          }
        });
        let data = XLSX.utils.json_to_sheet(buffer);
        const workbook = XLSX.utils.book_new();
        const filename = `Reporte paciente`;
        XLSX.utils.book_append_sheet(workbook, data, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      });
    },
    createAppointment() {
      this.$router.push({
        name: "create_appointment",
        query: { person: this.person.id }
      });
    },
    createGlobalAppointment() {
      this.$router.push({
        name: "clinic_procedures_create",
        query: { person: this.person.id }
      });
    },
    createLaboratoryAppointment() {
      this.$router.push({
        name: "clinic_laboratory_appointment_create",
        query: { person: this.person.id }
      });
    },
    searchPerson() {
      console.log("Buscando");
      ApiService.get("/person/search/dni", `?dni=${this.form.dni}`).then(
        res => {
          console.log(res);
          this.person = res.data;
        }
      );
    },
    editPerson() {
      this.$router.push({
        name: "clinic_person_edit",
        params: { id: this.person.id }
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "Form" }
    ]);
  }
};
</script>
